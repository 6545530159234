import { Button } from 'components'
import { mykonosBlue } from 'components/baseColor'
import styled from 'styled-components'
import { IS_FRAME_MOBILE, IS_FRAME_TABLET } from 'utils'

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 1rem;
  
  span {
    font-size: 0.875rem;
    letter-spacing: 0.25px;
  }

   div {
    font-size: 0.75rem;
  }

  ${IS_FRAME_TABLET} {
    display: block;
  }

  ${IS_FRAME_MOBILE} {
    display: block;
  }
`

export const PhoneNumberWrapper = styled.div`
  margin-top: 0.5rem;
`

export const BankSelectWrapper = styled.div`
  margin-top: 0.5rem;

  > span {
    color: #222b49;
  }

  ${IS_FRAME_TABLET} {
    margin-bottom: 0.875rem;
  }

  ${IS_FRAME_MOBILE} {
    margin-bottom: 1rem;
  }
`

export const SubmitButton = styled(Button)`
  color: ${mykonosBlue};
  font-weight: bold;
  letter-spacing: 0.29px;
  margin-left: auto;
  padding: 0.5rem 4rem;
  font-size: 0.9rem;
  display:block;

  &:not([disabled]):hover {
    color: #fff;
    background-color: ${mykonosBlue};
    border: solid 1px #fff;
  }

  ${IS_FRAME_TABLET} {
    width: 100%;
    margin-top: 2rem;
  }

  ${IS_FRAME_MOBILE} {
    width: 100%;
    margin-top: 2rem;
  }
` 
