import styled from 'styled-components'
import { lightDarkBlue } from 'components/baseColor'
import { IS_FRAME_MOBILE } from 'utils'

export const Wrapper = styled.div`
  margin: 1rem 6rem;
  font-family: "Open Sans", system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial,
  sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  color: ${lightDarkBlue};
  min-height: 75vh;

  h2 {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.25px;
  }

  ${IS_FRAME_MOBILE} {
    margin: 1rem 1rem 2rem;
    min-height: 75vh;

    
  }
`
