/* eslint-disable react/jsx-closing-bracket-location */
import React, { useEffect , useCallback , useState } from 'react'
import { Breadcrumbs } from 'components/AffiliateDashboard'
import { Wrapper } from './styles'
import { useToasts } from 'react-toast-notifications'
import { ProfileForm } from './components'
import { AffiliateProfileURL } from 'commons/API'
import AffiliateAPI from 'commons/API/affiliate'
import { Shimmer } from 'components'

const ProfileView = ({ location }) => {

  const { addToast } = useToasts()
  const [affiliate, setAffiliate] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  const fetchProfile = useCallback(async() => {
    setIsLoading(true)
    await AffiliateAPI.get(AffiliateProfileURL.GetProfile )
      .then((response) => {
        const data = response.data
        setAffiliate(data)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        if(error.response.status !== 403){
          addToast(error.response.data.message, { appearance: 'warning' })
        }
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchProfile()
  }, [fetchProfile])

  return (
    <Wrapper>
      <Breadcrumbs location={ location } />
      {
        isLoading
          ? <>
            <Shimmer />
            <Shimmer />
            <Shimmer />
            <Shimmer />
            <Shimmer />
            <Shimmer />
          </>
          : <ProfileForm 
            affiliate={ affiliate } 
            fetchProfile={ fetchProfile }
          />
      }
    </Wrapper>
  )
}

export default ProfileView
