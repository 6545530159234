import React from 'react'
import { AffiliateDashboardLayout } from 'components/AffiliateDashboard'
import ProfileView from 'domains/AffiliateDashboard/Profile'
import withAuth from '../../hocs/with-auth'

const redirectUrl = '/affiliate-dashboard/login?redirectUrl=/affiliate-dashboard/profile'

const Profile = ({ location }) => (
  <AffiliateDashboardLayout>
    <ProfileView location={ location }/>
  </AffiliateDashboardLayout>
)

export default withAuth(Profile, redirectUrl)
