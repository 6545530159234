import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { 
  InputCountry, 
  InputPhone, 
  InputText, 
  Select 
} from 'components'
import { 
  Grid, 
  PhoneNumberWrapper, 
  BankSelectWrapper, 
  SubmitButton 
} from './styles'
import { useToasts } from 'react-toast-notifications'
import { yupResolver } from '@hookform/resolvers/yup'
import { BankURL, AffiliateProfileURL } from 'commons/API'
import { ProfileFormSchema } from './profile-form-schema'
import { useAuth } from '../../../../../providers/auth-provider'
import AffiliateAPI from 'commons/API/affiliate'

const ProfileForm = ({ affiliate = {}, fetchProfile }) => {
  const { setUser, AFFILIATE_USER } = useAuth()
  const [banks, setBanks] = useState([])
  const { addToast } = useToasts()
  const [countryCode, setCountryCode] = useState(affiliate.country_iso2)

  const fetchBankList = () => {
    AffiliateAPI.get(BankURL.GetListBank).then((response) => {
      const data = response.data.banks
      setBanks(data)
    })
  }

  const handleUpdateProfile = async ({ values, newToken }) => {
    await AffiliateAPI.put(AffiliateProfileURL.PutProfile, values)
      .then((response) => {
        fetchProfile()
        const updatedName = response.data.affiliate_user?.name
        setUser((prevValue) => ({
          ...prevValue,
          name: updatedName
        }))

        // update data on navbar
        const oldData = JSON.parse(localStorage.getItem(AFFILIATE_USER))
        oldData.name = updatedName

        localStorage.setItem(AFFILIATE_USER, JSON.stringify(oldData))

        addToast('Successfully updated your profile', { appearance: 'success' })
      })
      .catch((error) => {
        if (error.message === 'Network Error') {
          addToast(`${error.message}. Please Check Your Internet Connection`, {
            appearance: 'warning'
          })
        } else {
          addToast(error.response.data.message, { appearance: 'warning' })
        }
      })
  }

  const handleSubmitForm = async (values, e) => {
    values.affiliate_bank_id = values.affiliate_bank?.id
    values.country = values.selected_country.countryName

    handleUpdateProfile({ values })
  }

  useEffect(() => {
    fetchBankList()
  }, [])

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    control,
    getValues
  } = useForm({
    resolver      : yupResolver(ProfileFormSchema),
    mode          : 'onTouched',
    defaultValues : {
      company_name    : '',
      name            : '',
      company_address : '',
      email           : '',
      dial_code       : '62',
      phone_number    : '',
      country_iso2    : 'id',
      npwp            : undefined,
      affiliate_bank  : {
        code : '',
        id   : '',
        name : ''
      },
      bank_account      : '',
      bank_account_name : '',
      selected_country  : { countryName: '' }
    }
  })

  useEffect(() => {
    // assign BE data to react form
    const keys = Object.keys(affiliate)
    if (keys.length) {
      keys.map((key) => {
        const value = affiliate[key]
        if(value) setValue(key, value)
        return ''
      })
      
      setCountryCode(affiliate.country_iso2.toLowerCase())
      setValue('selected_country.countryName', affiliate.country)
      setValue('dial_code', affiliate.dial_code.toLowerCase())
    }
  }, [affiliate, setValue])

  return (
    <>
      <h2>Edit Profile</h2>
      <form 
        onSubmit={ handleSubmit(handleSubmitForm) } 
        autoComplete='off'
      >
        <Grid>
          <Controller
            render={ ({ field }) => (
              <InputText
                title='Company Name'
                placeholder='Enter your company name'
                error={ errors?.company_name?.message ? errors.company_name.message : '' }
                ref={ field.ref }
                { ...field }
              />
            ) }
            control={ control }
            name='company_name'
          />

          <Controller
            render={ ({ field, ref }) => (
              <InputText
                title={ 'Affiliate Account Name' }
                placeholder='Enter your account name here'
                error={ errors?.name ? errors.name.message : '' }
                ref={ field.ref }
                { ...field }
              />
            ) }
            control={ control }
            name='name'
          />

          <Controller
            render={ ({ field }) => (
              <InputText
                title='Company Address'
                placeholder='Enter your company address'
                error={ errors?.company_address?.message ? errors.company_address.message : '' }
                ref={ field.ref }
                { ...field }
              />
            ) }
            control={ control }
            name='company_address'
          />

          <Controller
            render={ ({ field }) => (
              <InputText
                disabled={ true }
                title={ 'Email' }
                placeholder='Enter your email here'
                error={ errors?.email?.message ? errors.email.message : '' }
                ref={ field.ref }
                { ...field }
              />
            ) }
            control={ control }
            name='email'
          />

          <Controller
            render={ ({ field }) => (
              <InputCountry
                withSearch
                title='Country'
                keySearch='countryName'
                svgPosition={{ top: '4px' }}
                borderColor='#bcbfc8'
                py='4px'
                errorText={
                  errors?.selected_country?.countryName
                    ? errors.selected_country?.countryName.message
                    : ''
                }
                selectedItem={ getValues().selected_country }
                selectedKey='countryName'
                placeholder='Pick your country here'
                ref={ field.ref }
                { ...field }
                onChange={ (country) => {
                  setCountryCode(country.alpha2Code.toLowerCase())
                  setValue('selected_country.countryName', country.countryName, {
                    shouldValidate: true
                  })
                  setValue('country_iso2', country.alpha2Code.toLowerCase())
                } }
                value={ getValues().selected_country }
              />
            ) }
            control={ control }
            name='selected_country'
          />

          <PhoneNumberWrapper>
            <Controller
              render={ ({ field }) => (
                <InputPhone
                  title={ 'Phone Number' }
                  defaultCountry={ countryCode || 'id' }
                  value={ getValues().phone_number }
                  error={ errors?.phone_number?.message ? errors.phone_number.message : '' }
                  { ...field }
                  placeholder={ 'Enter your phone number here' }
                  onChange={ (isValid, phone, country) => {
                    setValue('phone_number', phone.replace(/\D/, '').replace(/^0+/, ''), { shouldValidate: true })
                  } }
                  handleSelectFlag={ (num, country) => {
                    setValue('dial_code', country.dialCode)
                    setValue('country_iso2', country.iso2)
                    setCountryCode(country.iso2)
                  } }
                  ref={ field.ref }
                />
              ) }
              control={ control }
              name='phone_number'
            />
          </PhoneNumberWrapper>

          <BankSelectWrapper>
            <span>Bank Name</span>
            <Controller
              render={ ({ field }) => (
                <Select
                  type='text'
                  withSearch
                  title='Bank'
                  keySearch='name'
                  svgPosition={{ top: '5px' }}
                  borderColor='#bcbfc8'
                  py='4px'
                  options={ banks }
                  selectedItem={
                    getValues().affiliate_bank?.name
                      ? getValues().affiliate_bank
                      : { name: 'Select bank' }
                  }
                  selectedKey='name'
                  errorText={ errors?.affiliate_bank?.id ? errors.affiliate_bank.id.message  : '' }
                  onChange={ (bank) => {
                    setValue('affiliate_bank', bank)
                  } }
                />
              ) }
              control={ control }
              name='affiliate_bank'
            />
          </BankSelectWrapper>

          <Controller
            render={ ({ field }) => (
              <InputText
                type='text'
                title={ 'Bank Account Number' }
                placeholder='Enter bank account number here'
                error={ errors?.bank_account ? errors.bank_account.message : '' }
                ref={ field.ref }
                { ...field }
                onChange={ (e) => setValue('bank_account', e.target.value.replace(/\D/, '')) }
              />
            ) }
            control={ control }
            name='bank_account'
          />

          <Controller
            render={ ({ field }) => (
              <InputText
                title='Bank Account Name'
                placeholder='Enter bank account name here'
                error={ errors?.bank_account_name?.message ? errors.bank_account_name.message : '' }
                ref={ field.ref }
                { ...field }
              />
            ) }
            control={ control }
            name='bank_account_name'
          />

          <Controller
            render={ ({ field }) => (
              <InputText
                title={ 'TIN' }
                placeholder='Enter Tax Identification Number (TIN) here'
                error={ errors?.npwp ? errors.npwp.message : '' }
                ref={ field.ref }
                { ...field }
                // always put onChange below {...field}
                onChange={ (e) => setValue('npwp', e.target.value.replace(/\D/, '')) }
              />
            ) }
            control={ control }
            name='npwp'
          />
        </Grid>

        <SubmitButton
          type='submit'
          buttonType='warning'
          disabled={ isSubmitting }
          isLoading={ isSubmitting }
        >
          Save
        </SubmitButton>
      </form>
    </>
  )
}

export default ProfileForm
