import * as Yup from 'yup'

export const ProfileFormSchema = Yup.object().shape({
  company_name: Yup.string()
    .min(4, 'Minimum 4 characters')
    .max(30, 'Maximum 30 characters')
    .required('Required'),
  name: Yup.string()
    .min(4, 'Minimum 4 characters')
    .max(30, 'Maximum 30 characters')
    .required('Required'),
  company_address: Yup.string()
    .min(7, 'Minimum 7 characters')
    .max(300, 'Maximum 300 characters')
    .required('Required'),
  dial_code: Yup.string()
    .required('Required'),
  phone_number: Yup.string()
    .min(4, 'Minimum 4 digits')
    .max(14, 'Maximum 14 digits')
    .required('Required'),
  email: Yup.string()
    .min(4, 'Minimum 4 characters')
    .max(30, 'Maximum 30 characters')
    .email('Your email is not valid')
    .required('Required'),
  npwp: Yup.string()
    .matches('^[0-9]*$', 'Your TIN is invalid')
    .max(30, 'Maximum 30 digits')
    .nullable(true)
    .test(
      'empty-or-4-characters-check',
      'Minimum 4 digits',
      (npwp) => !npwp || npwp.length >= 4
    )
    .required('Required'),
  bank_account: Yup.string()
    .required('Required')
    .matches('^[0-9]*$', 'Your bank account number is invalid')
    .min(6, 'Minimum 6 digits')
    .max(18, 'Maximum 18 digits'),
  bank_account_name: Yup.string()
    .min(4, 'Minimum 4 characters')
    .max(30, 'Maximum 30 characters')
    .required('Required'),
  affiliate_bank: Yup.object().shape({
    id: Yup.string()
      .required('Required')
  }),
  selected_country: Yup.object().shape({
    countryName: Yup.string()
      .required('Required')
  })
})
